import type { LocationQueryRaw } from 'vue-router'

export const useDestinationStore = defineStore('selectedDestination', () => {

  // Config
  const { locale, t } = useI18n()
  const route = useRoute()
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()

  // GTM
  const { proxy } = useScriptGoogleTagManager()

  // Stores
  const currencyStore = useCurrencyStore()
  const { currency } = storeToRefs(currencyStore)
  const { isHome } = useIsHome()

  // Estado reactivo
  const cookieDestination: Ref<Destination | null> = useCookie('destination', { maxAge: 86400 }) // cookie destino, expira en 24 horas
  const selectedDestination: Ref<Destination | null> = ref(cookieDestination.value ?? null)
  const labelDestination: ComputedRef<string> = computed(() => route.query?.['data-search'] as string ?? cookieDestination.value?.name_translated ?? '')
  const destination_id: Ref<number | null> = ref(route.query?.['data-destinationId'] ? Number(route.query['data-destinationId']) : null)
  const distance: ComputedRef<number | null> = computed(() => selectedDestination.value?.category_id === 3 ? selectedDestination.value?.search_distance : 20)

  const latitude = computed(() => {
    const location = selectedDestination.value?.location?.split(',') || ['0', '0']
    return route.query?.['data-latitude'] ? Number(route.query?.['data-latitude']) : Number(location[0])
  })
  
  const longitude = computed(() => {
    const location = selectedDestination.value?.location?.split(',') || ['0', '0']
    return Number(route.query?.['data-longitude'] ?? location[1])
  })
  
  watch(selectedDestination, async (newDest, oldDest) => {

    if (newDest) {

      // ID destino
      destination_id.value = newDest?.category_id === 3 ? null : newDest?.element_id ? newDest.element_id : null

      // Cookies
      cookieDestination.value = newDest

      // Datalayers num_hotels = 0
      if(newDest?.num_hotels == 0){
        proxy.dataLayer.push({ event: 'BusquedaSinHotelesEvent', value: newDest?.name_general })
      }

      // Actualizar rutas o redireccionamiento hotel o search (si no es home)
      if(!isHome.value){
        const destinationChanged = newDest && oldDest && oldDest?.name_translated != newDest?.name_translated && newDest?.element_id != Number(route.query?.['data-destinationId'])
        handleDestinationRedirect(newDest, destinationChanged ?? false)
      }

    }

  })

  // Funciones
  const initDestination = async (currentRoute: { query: LocationQueryRaw }) => {
    try {
      if(currentRoute.query?.['data-destinationId']){
        updateDestination(currentRoute)
      }else if(!cookieDestination.value){ // si esta set destinationId en url, obtener info destino con id
        const miIp = await $fetch('https://api.ipify.org', { server: false, timeout: 2000 })
        if (miIp) {
          const geoIpData: GeoIp = await $fetch('/api-web/geoip', {
            method: 'post',
            body: { miIp },
            server: false,
          })
          if (geoIpData) {
            getDestinationGeoip(geoIpData)
          }
        }
      }
    } catch (error) {
      console.error('Error fetching GeoIP data:', error)
    }
  }

  const getDestinationGeoip = (geoIpData: GeoIp) => { // obtener info destino con lat y lon
    const latitude = geoIpData?.location?.latitude
    const longitude = geoIpData?.location?.longitude

    useFetch(`${runtimeConfig.public.API_CLIENT_BASE_URL}/destination`, {
      headers: { "Content-Type": "application/json" },
      query: { 
        latitude,
        longitude,
        locale
      },
      server: false,
      onResponse({ response }) {
        const destinationInfo = response?._data?.data[0]
        
        // Moneda
        const country = destinationInfo?.country_id // código país
        currencyStore.updateCurrencyByCountry(country)

        selectedDestination.value = destinationInfo ?? {} // actualizar info ubicación
      }
    })
  }

  const updateDestination = async (currentRoute: { query: LocationQueryRaw }) => { // actualizar destino a partir de route o si es hotel
    if(selectedDestination.value?.category_id === 1 || currentRoute.query?.['data-destinationId']){
      let idDestino = null
      
      if(currentRoute.query?.['data-destinationId']){ // si esta set destinationId en url, obtener info destino con id
        idDestino = currentRoute.query?.['data-destinationId'] ?? null
      } else if(selectedDestination.value?.category_id === 1){ // si es hotel, obtener info destino con id
        idDestino = selectedDestination.value?.destination_id ?? null
      }

      if(Number(idDestino) != selectedDestination.value?.element_id){
        const { data: destinationData }: any = await useFetch(`${useBaseUrl()}/destination-id`, {
          headers: { "Content-Type": "application/json" },
          query: { 
            destination_id: idDestino,
            locale
          },
          onResponseError(res) {
            logError(res)
          }
        })
        if(destinationData.value?.data){
          selectedDestination.value = removeCookieFileds(destinationData.value?.data) ?? {}
        }
      }
    }
  }

  const handleDestinationRedirect = (destination: Destination, changed: boolean) => {
    const selectedDiaStore = useSelectedDayStore()
    const { selectedDay } = storeToRefs(selectedDiaStore)

    const [latitude, longitude] = (destination?.location || '').split(',')
    const query = {
      'data-destinationId': destination?.category_id === 3 ? null : destination?.category_id ? destination?.category_id === 1 ? destination?.destination_id : destination?.element_id : undefined, // undefined para no añadir a la url el data-destinationId
      'data-longitude': longitude,
      'data-latitude': latitude,
      'data-distance': destination?.search_distance,
      'data-search': destination?.name_translated,
      'data-country': destination?.country_name_translated,
      'search': destination?.name_translated,
      'data-checkinDate': selectedDay.value ? formatDateGuion(selectedDay.value) : '',
      'GFakeDate': selectedDay.value ? formatFakeDate(selectedDay.value) : '',
      'curr': currency.value
    }

    if (destination?.category_id === 1) { // redireccionar hotel
      if(changed){
        redirectHotel(destination, query)
      }
    } else {
      if(changed){ // redireccionar search
        navigateTo({
          path: `/${locale.value}/search`,
          query
        })
      }else{ // actualizar url solamente si esta en search
        if (route.path.includes('search')) {
          router.push({ query })
        }
      }
    }
  }

  const redirectHotel = async (newUbicacion: Destination, query: LocationQueryRaw) => {
    const regexHotel = /(?<=\/[a-z]{2}\/)[^\/]+(?=\.html)/
    const matchHotel = newUbicacion?.['url'].match(regexHotel)
    const slug = matchHotel ? matchHotel[0] : ''
    const path = `/${locale.value}/${slug}.html`

    navigateTo({
      path,
      query
    })
  }

  // Función para obtener la ubicación del navegador
  const getLocation = (callback: any) => {
    if (!navigator.geolocation) {
      callback({ err: t('errors.ubicacion.support') })
      return
    }
  
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "denied") {
        callback({ err: t('errors.ubicacion.block') })
        return
      }
  
      navigator.geolocation.getCurrentPosition(
        (position) => {
          callback({ data: `${position.coords.latitude}, ${position.coords.longitude}` })
        },
        (err) => {
          switch (err.code) {
            case 1:
              callback({ err: t('errors.ubicacion.permission') })
              break;
            case 2:
              callback({ err: t('errors.ubicacion.gps') })
              break;
            case 3:
              callback({ err: t('errors.ubicacion.timeout') })
              break;
            default:
              callback({ err: t('errors.ubicacion.error') })
          }
        }
      )
    })
  }

  return {
    selectedDestination,
    cookieDestination,
    labelDestination,
    destination_id,
    distance,
    latitude,
    longitude,
    initDestination,
    updateDestination,
    handleDestinationRedirect,
    getDestinationGeoip,
    getLocation
  }
})