// Moneda
export const useCurrencyStore = defineStore('currency', () => {
  const currency = useCookie('currency', { default: () => ('EUR'), watch: true })

  const updateCurrencyByCountry = async (country: string) => {
    currency.value = await getCurrencyByCountry(country)
  }

  return { currency, updateCurrencyByCountry }
})
